import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { getBackendEnvironment } from './api/apiEnvConfig';
import { IS_PREVIEW } from './constants/environment';


if (process.env.NODE_ENV === 'development' || IS_PREVIEW) {
	window.apiUrl = getBackendEnvironment().rest;
	window.graphqlUrl = getBackendEnvironment().graphql;
	window.sentryEnv = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'local';
	window.useGraphQlDevtools = true;
	loadDevMessages();
	loadErrorMessages();
}

if (
	process.env.REACT_APP_SENTRY_ENVIRONMENT !== 'prod' &&
	process.env.REACT_APP_SENTRY_ENVIRONMENT !== 'ci-integration'
) {
	window.previewBaseName = process.env.REACT_APP_PREVIEW_BASE_NAME;
}

import('./bootstrap');
